var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DeleteTemplate',{attrs:{"deleteDialog":_vm.deleteDialog},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Delete Confirmation")]},proxy:true},{key:"text",fn:function(){return [_c('h3',{staticClass:"font-size-16 font-weight-500 delete-text"},[_c('span',{staticClass:"pb-1 d-block"},[_vm._v("Once you delete this, you won't be able to retrieve this later. Are you sure you want to delete ?")]),_c('span',{staticClass:"pt-1 d-block"},[_vm._v("To delete this, type "),_c('span',{staticClass:"barcode-text"},[_vm._v(_vm._s(_vm.uniqueId))]),_vm._v(" in below field.")])])]},proxy:true},{key:"validate",fn:function(){return [_c('v-form',{ref:"deleteForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteItem.apply(null, arguments)}},model:{value:(_vm.deleteValid),callback:function ($$v) {_vm.deleteValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"deleteValid"}},[_c('v-text-field',{staticClass:"pt-4",attrs:{"rules":[
          _vm.validateRules.required(_vm.deleteValidateText, 'Delete #'),
          _vm.validateRules.deleteValidation(
            _vm.deleteValidateText,
            'Delete #',
            _vm.uniqueId
          ),
        ],"dense":"","filled":"","solo":"","flat":"","persistent-hint":"","hint":'Type ' + _vm.uniqueId + ' in above field.',"color":"cyan","label":"Delete #"},on:{"paste":function($event){$event.preventDefault();}},model:{value:(_vm.deleteValidateText),callback:function ($$v) {_vm.deleteValidateText=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"deleteValidateText"}})],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mx-2 custom-bold-button white--text",attrs:{"depressed":"","disabled":_vm.deleteLoading || !_vm.deleteValid,"loading":_vm.deleteLoading,"color":"red lighten-1"},on:{"click":_vm.deleteItem}},[_vm._v(" Yes, Delete it ")]),_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"depressed":"","disabled":_vm.deleteLoading},on:{"click":function($event){return _vm.$emit('delete:close', true)}}},[_vm._v(" No, Keep it ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }